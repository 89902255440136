// app/[locale]/error.tsx

"use client";

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

export default function ErrorPage({ error, reset }: ErrorPageProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <h1>Something went wrong.</h1>
      <p>{error.message}</p>
      <button onClick={reset} style={{ marginTop: "20px", padding: "10px 20px" }}>
        Try Again
      </button>
    </div>
  );
}
